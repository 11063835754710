import React from "react";
import { Link } from "gatsby";

import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import SeoAcceptablePolicy from "../components/SeoAcceptablePolicy";
import CookieBanner from "../components/CookieBanner";

const AcceptableUsePolicyPage = () => {
  return (
    <>
      <div id="top" name="top" className="bg-gray">
        <SeoAcceptablePolicy />
        <Header />
        <main className="container flex flex-col h-fit w-full py-10 md:py-10 xl:py-10">
          <div className="flex flex-col gap-5 text-center mb-10">
            <h1 className="text-4xl">Acceptable Use Policy</h1>
            <h4 className="text-xl">Updated November 3, 2022</h4>
          </div>

          <p className="mb-10">
            The following Acceptable Use Policy is valid from and was last updated on November 3, 2022.
          </p>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Welcome to Slashscore! </h2>
            <p className="body2">
              This acceptable use policy sets out the terms between you and us under which you may access our web
              application, Slashscore, including any other branded version of it that contains a link to this policy.
              This acceptable use policy applies to all users of and visitors to Slashscore.
            </p>
            <p className="body2">
              Your use of Slashscore means that you accept, and agree to abide by, all the policies in this acceptable
              use policy, which supplement our&nbsp;
              <Link to="/terms-of-service" className="underline">
                terms of service
              </Link>
              .
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Prohibited uses</h2>
            <p className="body2">You may use Slashscore only for lawful purposes. You may not use Slashscore:</p>
            <ul className="list-disc pl-14">
              <li className="body2">
                In any way that breaches any applicable local, national, or international law or regulation.
              </li>
              <li className="body2">
                In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
              </li>
              <li className="body2">For the purpose of harming or attempting to harm minors in any way.</li>
              <li className="body2">
                To send, knowingly receive, upload, download, use, or re-use any material that does not comply with our
                content standards
              </li>
              <li className="body2">
                To transmit, or procure the sending of, any unsolicited or unauthorized advertising, promotional
                material, or any other form of similar solicitation (spam).
              </li>
              <li className="body2">
                To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms,
                time-bombs, keystroke loggers, spyware, adware, or any other harmful programs or similar computer code
                designed to adversely affect the operation of any computer software or hardware.
              </li>
            </ul>
            <p className="body2">You also agree:</p>
            <ul className="list-disc pl-14">
              <li className="body2">
                Not to reproduce, duplicate, copy or re-sell any part of Slashscore in contravention of the provisions
                of our &nbsp;
                <Link to="/terms-of-service" className="underline">
                  terms of service
                </Link>
                .
              </li>
              <li className="body2">Not to access without authority, interfere with, damage, or disrupt:</li>
              <ul className="list-circle pl-20">
                <li className="body2">any part of Slashscore;</li>
                <li className="body2">any equipment or network on which Slashscore is stored;</li>
                <li className="body2">any software used in the provision of Slashscore; or</li>
                <li className="body2">any equipment, network, or software owned or used by any third party.</li>
              </ul>
            </ul>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Content standards</h2>
            <p className="body2">
              These content standards apply to any and all material that you contribute to Slashscore (contributions),
              and to any features associated with it.
            </p>
            <p className="body2">
              You must comply with the spirit and the letter of the following standards. The standards apply to each
              part of any contribution and its whole.
            </p>
            <p className="body2">Contributions must:</p>
            <ul className="list-disc pl-14">
              <li className="body2">Be accurate (where they state facts).</li>
              <li className="body2">Be genuinely held (where they state opinions).</li>
              <li className="body2">
                Comply with applicable laws in the RO and in any country from which they are posted.
              </li>
            </ul>
            <p className="body2">Contributions must not:</p>
            <ul className="list-disc pl-14">
              <li className="body2">Contain any material which is defamatory of any person.</li>
              <li className="body2">Contain any material which is obscene, offensive, hateful, or inflammatory.</li>
              <li className="body2">Promote sexually explicit material.</li>
              <li className="body2">Promote violence.</li>
              <li className="body2">
                Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation, or
                age. Infringe any copyright, database right, or trademark of any other person.
              </li>
              <li className="body2">Be likely to deceive any person.</li>
              <li className="body2">
                Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of
                confidence.
              </li>
              <li className="body2">Promote any illegal activity.</li>
              <li className="body2">
                Be threatening, abusive, or invading another&apos;s privacy, or cause annoyance, inconvenience, or
                needless anxiety.
              </li>
              <li className="body2">Be likely to harass, upset, embarrass, alarm, or annoy any other person.</li>
              <li className="body2">
                Be used to impersonate any person, or to misrepresent your identity or affiliation with any person.
              </li>
              <li className="body2">Give the impression that they emanate from us if this is not the case.</li>
              <li className="body2">
                Advocate, promote, or assist any unlawful act such as (by way of example only) copyright infringement or
                computer misuse.
              </li>
            </ul>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Suspension and termination</h2>
            <p className="body2">
              We will determine, at our discretion, whether there has been a breach of this acceptable use policy
              through your use of Slashscore. When a breach of this policy has occurred, we may take such action as we
              deem appropriate.
            </p>
            <p className="body2">
              Failure to comply with this acceptable use policy constitutes a material breach of the&nbsp;
              <Link to="/terms-of-service" className="underline">
                terms of service
              </Link>
              &nbsp; upon which you are permitted to use Slashscore, and may result in our taking all or any of the
              following actions:
            </p>
            <ul className="list-disc pl-14">
              <li className="body2">Immediate, temporary, or permanent withdrawal of your right to use Slashscore.</li>
              <li className="body2">
                Immediate, temporary, or permanent removal of any posting or material uploaded by you to Slashscore.
              </li>
              <li className="body2">Issue of a warning to you.</li>
              <li className="body2">
                Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not
                limited to, reasonable administrative and legal costs) resulting from the breach.
              </li>
              <li className="body2">Further legal action against you.</li>
              <li className="body2">
                Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
              </li>
            </ul>
            <p className="body2">
              We exclude liability for actions taken in response to breaches of this acceptable use policy. The
              responses described in this policy are not limited, and we may take any other action we reasonably deem
              appropriate.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Changes to the acceptable use policy</h2>
            <p className="body2">
              We may revise this acceptable use policy at any time by amending this page. You are expected to check this
              page from time to time to take notice of any changes we make, as they are legally binding on you. Some of
              the provisions contained in this acceptable use policy may also be superseded by provisions or notices
              published elsewhere on Slashscore.
            </p>
          </div>
        </main>
        <Footer arrowLink="/acceptable-use-policy#top" />
        <CookieBanner />
      </div>
    </>
  );
};

export default AcceptableUsePolicyPage;
